.location {
  min-height: 100vh;
  padding: 125px 0 125px 0;

  .row {
    width: 100%;
    height: 500px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
