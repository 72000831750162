@import './variable';
@import './fonts';
@import './pages/home';
@import './pages/loader';
@import './parts/header';
@import './parts/navbar';
@import './parts/couple';
@import './parts/events';
@import './parts/moment';
@import './parts/location';
@import './parts/greeting-card';
@import './parts/footer';
@import './components/counter';
@import './components/title';
@import './components/bridegroom';
@import './components/card';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #faf3e4; /* or whatever color you want */
}

.app {
  background: #faf3e4;
  font-family: 'Share', cursive;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0.5vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee7d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f5eddb;
}

// /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  filter: grayscale(10);
}