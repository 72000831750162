footer {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f88da431;

  p {
    font-size: 14px;
    color: $fourthiary;
    margin: 0;

    a {
      color: $secondary;
      text-decoration: underline;
    }
  }
}
