/* styles.css */

.love-story-card {
  height: 300px;
  max-width: 350px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2); /* Transparan glossy background */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  position: relative; /* Added */
  margin-bottom: 20%;
}

.story-section {
  margin-bottom: 20px;
  text-align: center;
  color: #e91e63;
  padding-top: auto;
  font-size: 20px;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-visible {
  opacity: 1;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 200px;
  position: absolute; /* Added */
  bottom: 20px; /* Added */
  left: 20px; /* Added */
  right: 20px; /* Added */
}

.pagination button {
  background-color: #e91e63; /* Warna tombol */
  color: #fff; /* Warna teks tombol */
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.pagination button:hover {
  background-color: #e91e63; /* Warna tombol saat dihover */
}

.pagination span {
  font-size: 18px;
  font-weight: bold;
  color: #e91e63; /* Warna teks nomor halaman */
}


.special-text {
  color: #e91e63; /* Warna teks */
  font-size: 30px; /* Ukuran teks */
  font-weight: bold; /* Ketebalan teks */
  text-align: center; /* Posisi teks */
  margin-bottom: 10px; /* Jarak teks ke elemen lain */
}