/* Rsvp.css */

body {
  background-color: #f8f8f8; /* Warna latar belakang halaman */
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.form {
  background-color: rgba(255, 255, 255, 0.2); /* Transparansi untuk efek glossy */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  width: 75%; /* Lebar formulir */
  max-width: 325px; /* Lebar maksimum formulir */
  margin: auto; /* Posisi di tengah */
}

.form h1 {
  color: #e91e63; /* Warna judul */
  text-align: center;
}

.form label {
  display: block;
  margin: 10px 0;
  color: #333;
}

.input,
.select,
.textarea {
  width: 80%;
  padding: 7px;
  margin-bottom: 10px;
  border: 1px solid #e91e63;
  border-radius: 5px;
}

.button-container {
  text-align: center;
}

.send-button {
  background-color: #e91e63; /* Warna tombol */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #e83e8c; /* Warna tombol saat dihover */
}

#guestList {
  max-height: 300px; /* Tinggi maksimum daftar tamu */
  overflow-y: auto; /* Menambahkan scrollbar jika kontennya melebihi tinggi maksimum */
  background-color: rgba(255, 255, 255, 0.2); /* Transparansi untuk efek glossy */
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Efek bayangan */
  width: 80%; /* Lebar daftar tamu */
  max-width: 600px; /* Lebar maksimum daftar tamu */
  margin: auto; /* Posisi di tengah */
  margin-top: 20px;
}

.chatBalloon {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Efek bayangan */
  border: 2px solid #e91e63; /* Garis tepi untuk pesan */
}

.chatBalloon p {
  margin: 5px 0;
}

.pesan {
  color: #e91e63; /* Warna pesan */
  font-style: italic;
  font-size: 0.9em;
}

.nama {
  color: #333; /* Warna nama */
  font-size: 0.7em;
}

.kehadiran {
  color: #555; /* Warna tanggal */
  font-size: 0.7em;
}

.tanggal {
  color: #555; /* Warna tanggal */
  font-size: 0.7em;
}

/* Tambahan untuk membuat halaman responsif */
@media (max-width: 600px) {
  .form, #guestList {
      width: 90%;
  }
}