@keyframes bounceIn {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.kartus {
  max-width: 350px; /* Increase the max-width on hover */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 25px;
  backdrop-filter: blur(10px);
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  margin: 0 auto; /* Center the card horizontally */
  text-align: center;
  transition: max-width 0.3s ease; /* Add transition effect to max-width */
  margin-bottom: 15%;
}

.info-container {
  display: none;
  margin-top: 10px;
  animation: bounceIn 0.6s ease;
  transition: opacity 0.3s ease; /* Add transition effect to opacity */
}

.info-container.active {
  display: block;
  opacity: 1; /* Set opacity to 1 when the menu is active */
  max-height: 75%;
}

.h2-class {
  font-size: 1.5rem; /* Adjust the font size as needed */
  margin-bottom: 10px;
  color: #000000;
  border: 2px solid #e91e63;
  border-radius: 10px;
}

.kartus p {
  border: 2px solid #e91e63;
  border-radius: 25px;
  border-width: auto;
  display: inline-block;
  font-size: 14px; /* Adjust the font size as needed */
  margin-bottom: 10px;
  color: #000000;
}

.button {
  height: 40px;
  width: 150px;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid #e91e63;
  overflow: hidden;
  border-radius: 30px;
  color: #e91e63;
  display: block;
  transition: all 0.5s ease-in-out;
  margin-bottom: 10px;
  margin: 10px auto; /* Center the button horizontally */
}

.btn-txt {
  z-index: 1;
  font-weight: 850;
  letter-spacing: 4px;
}

.type1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
  background-color: #e91e63;
  border-radius: 30px;
  visibility: hidden;
  height: 10px;
  width: 10px;
  z-index: -1;
}

.button:hover {
  box-shadow: 1px 1px 200px #252525;
  color: #fff;
  border: none;
}

.type1:hover::after {
  visibility: visible;
  transform: scale(100) translateX(2px);
}

.copy-button {
  background-color: #e91e63;
  border: 2px solid #c05688;
  border-radius: 10px;
  padding: 4px 8px;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px; /* Adjust the spacing between the button and the previous element (image/barcode) */
}

.copy-button:hover {
  background-color: #e91e63;
}

.gift-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.bank-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
